import { ActionIcon } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { apirc } from '~/configs/apirc';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { StrategyOverviewEditForm } from '~/pages/strategy_center_admin/strategy-overview/_components/StrategyOverviewEditForm';
export function StrategyOverviewEditFormModalButton(props) {
    return (<ActionIcon className={props.className} variant='transparent' size='xs' onClick={event => {
            pageStore.modalStore.showModalComponent(() => (<StrategyOverviewEditForm initialValues={props.valueOfStrategyOverview} onCancel={pageStore.modalStore.closeModal} onDelete={() => {
                    apirc.strategy
                        .http('delete', '/api/strategies/{strategyId}')
                        .request({ strategyId: props.valueOfStrategyOverview.id })
                        .then(pageStore.strategyOverviewStore.loadData)
                        .then(pageStore.modalStore.closeModal);
                }} onUpdate={updateDto => {
                    apirc.strategy
                        .http('patch', '/api/strategies/{strategyId}')
                        .request({ strategyId: props.valueOfStrategyOverview.id, ...updateDto })
                        .then(pageStore.strategyOverviewStore.loadData)
                        .then(pageStore.modalStore.closeModal);
                }}/>));
        }}>
      {props.children ? props.children : <IconEdit />}
    </ActionIcon>);
}
