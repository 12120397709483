import { Button, SimpleGrid, Space } from '@mantine/core';
import { Fragment } from 'react';
export const ModalFormButtons = (props) => {
    return (<Fragment>
      {props.onDelete && (<Button color='red' onClick={event => {
                if (!window.confirm('確定要刪除嗎？'))
                    return;
                if (props.onDelete) {
                    props.onDelete?.();
                }
                else {
                    alert('請傳入 props.onDelete');
                }
            }} {...props.DeleteButtonProps}>
          刪除
        </Button>)}
      <Space h='md'/>

      <SimpleGrid cols={2}>
        <Button color='gray' onClick={event => {
            props.onCancel();
        }}>
          取消
        </Button>

        <Button type='submit' color='blue'>
          確認
        </Button>
      </SimpleGrid>
    </Fragment>);
};
