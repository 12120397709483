import { Select, Switch, TextInput, Textarea, SimpleGrid, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CurrencySelect } from '~/pages/strategy_center_admin/_components/CurrencySelect';
import { ModalFormButtons } from '~/pages/strategy_center_admin/_components/ModalFormButtons';
import { getSelectProps } from '~/pages/strategy_center_admin/_utils/getSelectProps';
import { pageConfig } from '~/pages/strategy_center_admin/pageConfig';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { dayjs } from '@futures.tw/dayjs';
import { expectType } from '~/utils/tsd';
import { InstrumentSelector } from '~/pages/strategy_center_admin/_components/InstrumentSelector';
export function StrategyOverviewEditForm(props) {
    const form = useForm({
        initialValues: {
            ...props.initialValues,
        },
    });
    return (form.values && (<form onSubmit={form.onSubmit(values => {
            if (values.id) {
                props.onUpdate?.(values);
            }
            else {
                props.onCreate?.(values);
            }
        })}>
        <SimpleGrid cols={2}>
          <TextInput label='策略ID' variant='unstyled' readOnly {...form.getInputProps(expectType('id'))}/>

          <TextInput label='策略師帳號' variant='unstyled' readOnly {...form.getInputProps(expectType('userAccount'))}/>

          <TextInput label='更新時間' variant='unstyled' readOnly value={dayjs(props.initialValues.updatedDatetime).formatDateTime()}/>

          <TextInput label='上線日期' variant='unstyled' readOnly value={!props.initialValues.launchedDatetime
            ? `--`
            : dayjs(props.initialValues.launchedDatetime).formatDateTime()}/>
        </SimpleGrid>
        <SimpleGrid cols={2}>
          <TextInput label='策略名稱' {...form.getInputProps(expectType('name'))}/>

          <TextInput label='顯示名稱' {...form.getInputProps(expectType('displayName'))}/>
        </SimpleGrid>

        <Textarea label='策略描述' {...form.getInputProps(expectType('description'))}/>

        <SimpleGrid cols={2}>
          <InstrumentSelector label='交易商品' value={form.getInputProps(expectType('symbolRoot')).value} onChange={value => {
            form.setValues({ ...form.values, symbolRoot: value });
        }}/>

          <CurrencySelect value={form.values.currency || 'TWD'} onChange={value => {
            form.setValues({ ...form.values, currency: value });
        }}/>

          <TextInput label='K棒週期' {...form.getInputProps(expectType('barInterval'))}/>

          <Select label='K棒種類' {...getSelectProps({
        value: form.values.barType || '',
        data: pageConfig.barTypeOptions,
        onChange: changed => {
            form.setValues({ ...form.values, barType: changed.value });
        },
    })}/>

          <TextInput label='最大持倉' {...form.getInputProps(expectType('maxPosition'))}/>

          <TextInput label='初始資金' {...form.getInputProps(expectType('initialCapital'))}/>

          <TextInput label='標籤' description='逗號間隔可建立多組標籤 tag1,tag2' value={form.values.tags?.join(',')} onChange={event => {
            //轉換為array
            const tags = event.target.value.split(',').map(tag => tag.trim());
            //是否誤輸入只有,符號
            const hasOnlyCommas = tags.every(tag => tag === '');
            form.setValues({
                ...form.values,
                tags: tags.length === 0 || hasOnlyCommas ? [] : tags,
            });
        }}/>
        </SimpleGrid>

        <Space h='md'/>

        <SimpleGrid cols={2}>
          <Switch label={`啟用交易`} labelPosition='left' checked={form.values.enableTrading ?? false} onChange={event => {
            form.setValues({ ...form.values, enableTrading: event.currentTarget.checked });
        }}/>

          <Switch label={`啟用訂閱`} labelPosition='left' checked={form.values.enableSubscription ?? false} onChange={event => {
            form.setValues({ ...form.values, enableSubscription: event.currentTarget.checked });
        }}/>

          <Switch label={`啟用上傳`} labelPosition='left' checked={form.values.enableUpload ?? false} onChange={event => {
            form.setValues({ ...form.values, enableUpload: event.currentTarget.checked });
        }}/>

          <Switch label={`啟用自動轉倉`} labelPosition='left' checked={form.values.enableContractRoll ?? false} onChange={event => {
            form.setValues({ ...form.values, enableContractRoll: event.currentTarget.checked });
        }}/>
        </SimpleGrid>

        <Space h='md'/>

        <ModalFormButtons onDelete={props.onDelete} onCancel={() => {
            pageStore.modalStore.closeModal();
        }}/>
      </form>));
}
